import './App.css';

function App() {

  const letterSpan = (word) => {
    const wArray = word.split('');
    const spanWord = wArray.map(letter => 
      <span className='letra'>{letter}</span>
    );
    return spanWord;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>{letterSpan('PRÓXIMAMENTE')}</h1>
      </header>
    </div>
  );
}

export default App;
